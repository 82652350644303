import React from 'react';
import styled from 'styled-components';
import { device } from '../lib/device';
import AboutImg from '../images/aboutimg.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../style/app.scss';

const TermsTemplate = styled.div`
	display: block;
	margin-bottom: 100px;
	@media ${device.tablet} {
		margin-bottom: 50px;
	}
	h1,
	h4,
	h2,
	p {
		font-family: var(--font_harmoniasanspro_light);
		font-weight: var(--fw_light);
		color: var(--brand_black);
		text-align: center;
		@media ${device.desktopHhd} {
			margin: 0px 20px;
		}
	}
	h1 {
		font-size: 60px;
		@media ${device.desktopHhd} {
			font-size: 40px;
			margin: 20px 0;
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	h4 {
		font-size: 30px;
		color: var(--brand_blue);
		margin: 0;
		margin-top: 60px;
		@media ${device.desktopHhd} {
			font-size: 20px;
			margin-top: 30px;
		}
	}
	h2 {
		font-size: 60px;
		margin: 0;
		margin-bottom: 60px;
		margin-top: 20px;
		@media ${device.desktopHhd} {
			font-size: 40px;
			margin-bottom: 30px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 100;
		text-align: left;
		@media ${device.desktopHhd} {
			padding-bottom: 20px;
		}
		@media ${device.mobile} {
			font-size: 18px;
		}
	}
`;

const Terms = () => (
	<>
		<Header />
		<TermsTemplate>
			<div className="container_about">
				<h1>About</h1>
				<img src={AboutImg} alt="" />
				<h4>Oil Paintings / Graphics / Giclee</h4>
				<h2>Marianne Hesle</h2>
				<p>
					Marianne Hesle has worked as a visual artist for the past 15 years. The pictures are primarily oil
					paintings on canvas but also giclee and works with mixing technique. Marianne has been a student at
					Mosebekkskolen and the Interdisciplinary Art Institute in Bærum. She has also been taught by Arnfinn
					Eide, Jan Valentin Sæter and Bjørn Nilsen. Marianne Hesle is a member of NFUK.
				</p>
				<p>
					Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
					totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
					dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
					sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
					est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius
					modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
					veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea
					commodi consequatur.
				</p>
				<p>
					Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
					consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariaturLorem ipsum dolor sit amet,
					consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
					ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
				</p>
			</div>
		</TermsTemplate>
		<Footer />
	</>
);

export default Terms;
